<template>
  <div
    class="padding-top-grid-between padding-left-grid-between padding-right-grid-between padding-bottom-grid-between"
  >
    <div class="grid grid-cols-12 p-2 md:p-8">
      <div class="col-span-12 sm:col-span-8 m-4 mt-6">
        <h1
          class="uppercase text-brand-purple-500 font-serif font-bold text-3xl"
        >
          <n-html :text="$t('payments.title')" />
        </h1>
      </div>
      <div class="col-span-12">
        <n-alert
          class="mt-4 mb-8 sm:mx-0 mx-4"
          variant="danger"
          v-for="payment in paymentLinks"
          :key="payment"
        >
          <div class="flex flex-row font-semibold mb-4">
            <n-icon small name="Warning" class="mr-2"></n-icon>
            {{ payment.contract.navision_ext_id }}
          </div>
          <div class="mb-4">
            <n-html :text="$t('payments.payment_arrears')" />
          </div>
          <div class="mb-4">
            <n-html :text="$t('payments.arrears_total')" />
            € {{ payment.amount }}
          </div>
          <div>
            <n-html :text="$t('payments.arrears_now')" />
            <a
              class="underline cursor-pointer"
              :href="payment.payment_link"
              target="_blank"
            >
              <n-html :text="$t('payments.arrears_link')" />
            </a>
          </div>
        </n-alert>
        <n-alert
          class="mt-4 sm:mx-0 mx-4"
          variant="primary"
          v-if="customer.discount_percentage > 0"
        >
          {{ discountLine }}
        </n-alert>
        <div></div>
        <div v-if="payments.length">
          <n-table
            :columns="columns"
            :rows="rows"
            :initial-sort-column="sortBy"
            :show-pagination="true"
            :per-page="perPage"
            class="hidden md:block"
          />

          <div
            class="mt-10 md:hidden"
            v-for="contractNumber in Object.keys(groupedPaymentsByContract)"
            v-bind:key="contractNumber"
          >
            <h2 class="text-brand-purple-500 font-bold text-xl px-4">
              {{ contractNumber }}
            </h2>
            <CardPaymentMobileOverview
              v-for="payment in groupedPaymentsByContract[contractNumber]"
              v-bind:key="payment.id"
              :payment="payment"
              :active="true"
              @handleFile="downloadInvoice"
            />
          </div>
        </div>
        <n-alert
          class="mt-4 sm:mx-0 mx-4"
          variant="primary"
          v-else-if="loading"
        >
          <div class="flex flex-row font-semibold">
            <n-html :text="$t('payments.loading')" />
          </div>
        </n-alert>
        <n-alert class="mt-4 sm:mx-0 mx-4" variant="primary" v-else>
          <div class="flex flex-row font-semibold">
            <n-icon name="Info" class="mr-2"></n-icon>
            <n-html :text="$t('payments.no_items_title')" />
          </div>
          <div class="text-brand-purple-500 mt-2">
            <n-html :text="$t('payments.no_items')" />
          </div>
        </n-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "./../../mixins/layout";
import format from "../../mixins/format";
import { downloadFile, openFileInTab } from "../../utils";
import moment from "moment";
import CardPaymentMobileOverview from "../../components/payments/card-payment-mobile-overview";

export default {
  name: "pages.payments.index",
  components: {
    CardPaymentMobileOverview
  },
  mixins: [Layout, format],
  computed: {
    ...mapGetters({
      payments: "payments/getAll",
      customer: "customer/get"
      // paymentLinks: "payments/getAllLinks"
    }),
    rows() {
      return this.payments.map(p => {
        const hasExtraRow = p.reversal_description.length > 0;
        return {
          contract: p.contract?.navision_ext_id || "-",
          posting_date: p.posting_date,
          description: p.description,
          document_no: p.document_no,
          invoicing_period: p.invoicing_period,
          amount: `€ ${p.amount}`,
          is_open: p.is_open
            ? this.$t(`payments.payment_status.open`)
            : this.$t(`payments.payment_status.paid`),
          payment_date: p.payment_date,
          has_extra_row: hasExtraRow,
          extra_row: this.getExtraRow(p, hasExtraRow),
          payment: p
        };
      });
    },
    groupedPaymentsByContract() {
      const groupedPayments = {};
      this.rows.map(row => {
        if (row.contract in groupedPayments) {
          groupedPayments[row.contract] = [
            ...groupedPayments[row.contract],
            row
          ].sort((a, b) => new Date(b.posting_date) - new Date(a.posting_date));
          return;
        }

        groupedPayments[row.contract] = [row].sort(
          (a, b) => new Date(b.posting_date) - new Date(a.posting_date)
        );
      });
      return groupedPayments;
    },
    discountLine() {
      return this.$i18n
        .t("insurance.discount.label")
        .replace("{discount}", this.customer.discount_percentage);
    }
  },
  mounted() {
    this.filterPaymentsWithLinks();
  },
  methods: {
    visible() {
      return !this.isMobile(window.innerWidth);
    },
    getExtraRow(payment, hasRow) {
      if (hasRow) {
        let html = `
          <div>
            <div class="flex flex-row">
              <div class="min-w-52 text-brand-purple-500 font-semibold">
                {{reversalDescriptionLabel}}
              </div>
              {{reversalDescriptionContent}}
            </div>
            <div class="flex flex-row">
              <div class="min-w-52 text-brand-purple-500 font-semibold">
                {{collectionServiceStatusLabel}}
              </div>
              {{collectionServiceStatusContent}}
            </div>
          </div>
        `;
        html = html.replace(
          "{{reversalDescriptionLabel}}",
          this.$t("payments.table.reversal_description")
        );
        html = html.replace(
          "{{reversalDescriptionContent}}",
          payment.reversal_description === ""
            ? "-"
            : payment.reversal_description
        );
        html = html.replace(
          "{{collectionServiceStatusLabel}}",
          this.$t("payments.table.collection_service_status")
        );
        html = html.replace(
          "{{collectionServiceStatusContent}}",
          this.setReminderStatusTranslation(payment.reminder_status)
        );

        return html;
      } else {
        return null;
      }
    },
    setCollectionServiceStatusTranslation(status) {
      switch (status) {
        case "Closed":
          return this.$i18n.t("payments.collection_status.closed");
        case "Exported":
          return this.$i18n.t("payments.collection_status.exported");
        case "Cancelled":
          return this.$i18n.t("payments.collection_status.cancelled");
        default:
          return "-";
      }
    },
    setReminderStatusTranslation(status) {
      switch (status.toLowerCase()) {
        case "collection service":
          return this.$i18n.t("payments.reminder_status.collection_service");
        case "to close":
          return this.$i18n.t("payments.reminder_status.to_close");
        case "grace period":
          return this.$i18n.t("payments.reminder_status.grace_period");
        default:
          return "-";
      }
    },
    openFile(document_no) {
      this.$store
        .dispatch("payments/get_file", { document_no })
        .then(response => {
          openFileInTab(response.data.dataUri);
        });
    },
    downloadInvoice(document_no) {
      this.$store
        .dispatch("payments/get_file", { document_no })
        .then(response => {
          downloadFile(response.data.dataUri, response.data.filename);
        });
    },
    getRowClass(item, type) {
      if (
        item.reversal_description.length === 0 &&
        item.collection_service_status.length <= 1
      ) {
        return "body-row";
      }

      if (type === "row-details") {
        return "body-row";
      }
      return "";
    },
    filterPaymentsWithLinks() {
      var paymentsWithLink = this.payments.filter(function(payment) {
        if (payment.payment_link) return payment;
      });

      if (paymentsWithLink.length > 0) {
        this.$store.dispatch("payments/getAllPaymentLinks").then(response => {
          this.paymentLinks = response.data.filter(payment_link => {
            if (
              moment().isSameOrBefore(payment_link.payment_link_expiration_date)
            ) {
              return payment_link;
            }
          });
        });
      }
    }
  },
  watch: {
    payments(n) {
      this.loading = false;
      n.forEach(element => {
        if (
          element.reversal_description.length > 0 ||
          element.collection_service_status.length > 1
        ) {
          this.$set(element, "_showDetails", true);
        }
      });
      this.filterPaymentsWithLinks();
    }
  },
  data() {
    return {
      paymentLinks: null,
      loading: true,
      perPage: 5,
      sortBy: "posting_date",
      columns: [
        {
          title: this.$t("payments.table.contract"),
          key: "contract",
          sortable: true,
          col_width: "min-w-36"
        },
        {
          title: this.$t("payments.table.description"),
          key: "description",
          sortable: false,
          col_width: "min-w-52"
        },
        // {
        //   title: this.$t("payments.table.posting_date"),
        //   key: "posting_date",
        //   sortable: true,
        //   formatValue(value) {
        //     const formattedDate = moment(value).format("DD-MM-YYYY");
        //     return formattedDate !== "01-01-0001" ? formattedDate : "";
        //   },
        //   col_width: "w-2/12"
        // },
        {
          title: this.$t("payments.table.invoicing_period"),
          key: "invoicing_period",
          col_width: "min-w-64"
        },
        {
          title: this.$t("payments.table.amount"),
          key: "amount",
          sortable: true,
          col_width: "min-w-28"
        },
        {
          title: this.$t("payments.table.status"),
          key: "is_open",
          sortable: true,
          col_width: "min-w-32"
        },
        {
          title: this.$t("payments.table.payment_date"),
          key: "payment_date",
          sortable: true,
          formatValue(value) {
            const formattedDate = moment(value).format("DD-MM-YYYY");
            return formattedDate !== "01-01-0001" ? formattedDate : "";
          },
          col_width: "min-w-32"
        },
        {
          title: this.$t("payments.table.invoice"),
          key: "invoice",
          link: true,
          linkLabel: this.$t("payments.table.invoice"),
          linkIcon: "Pdf",
          linkAction: row => {
            this.openFile(row.payment.document_no);
          },
          col_width: "min-w-32"
        }
      ]
    };
  }
};
</script>

<style>
.head {
  background-color: #f3edf4;
  border-radius: 10px;
}

.head-text {
  color: #7e468f;
}

.details-card {
  background-color: #ffffff;
}

.body-row {
  border-bottom: 1px solid #ececec;
}

.body-col {
  vertical-align: middle !important;
}

.page-item .page-link {
  color: black !important;
  background-color: white !important;
  border-color: #dee2e6 !important;
}

.page-item.active .page-link {
  color: #7e468f !important;
  font-weight: bold;
  background-color: #f3edf4 !important;
  border-color: #7e468f !important;
}
</style>
