<template class="">
  <Accordion class="insurance-card p-0" no-padding>
    <accordion-header
      :collapsed="internal_collapsed"
      @click.native="internal_collapsed = !internal_collapsed"
    >
      <div class="flex flex-col lg:flex-row font-serif">
        <h4 :class="['text-xs font-bold leading-6 mr-5']">
          {{
            payment.invoicing_period != ""
              ? payment.invoicing_period
              : payment.description
          }}
        </h4>
      </div>
    </accordion-header>
    <accordion-body
      :collapsed="internal_collapsed"
      class="flex flex-col lg:flex-row"
    >
      <div class="w-full">
        <div
          class="border-b lg:border-t grid pb-4 content-between  grid-cols-4 gap-4 gap-x-12 text-xs justify-between"
        >
          <payment-detail-item
            :title="$t('payments.table.description')"
            :value="payment.description"
            class="!col-span-2"
          />
          <payment-detail-item
            :title="$t('payments.table.amount')"
            :value="payment.amount"
            class="!col-span-2"
          />
          <payment-detail-item
            :title="$t('payments.table.invoicing_period')"
            :value="format_value(payment.invoicing_period)"
            class="!col-span-4"
          />
          <payment-detail-item
            :title="$t('payments.table.payment_date')"
            :value="format_date(payment.payment_date)"
            class="!col-span-2"
          />
          <payment-detail-item
            :title="$t('payments.table.status')"
            :value="payment.is_open"
            class="!col-span-2"
          />
        </div>

        <div
          v-if="
            payment.payment.collection_service_status.trim() !== '' ||
              payment.payment.reversal_description.trim() !== ''
          "
          class="border-b pt-4 grid pb-4 content-between  grid-cols-4 gap-4 gap-x-12 text-xs justify-between"
        >
          <payment-detail-item
            :title="$t('payments.table.reversal_description')"
            :value="format_value(payment.payment.reversal_description)"
            class="!col-span-4"
          />
          <payment-detail-item
            :title="$t('payments.table.collection_service_status')"
            :value="format_value(payment.payment.collection_service_status)"
            class="!col-span-4"
          />
        </div>

        <div class="pt-4">
          <div :class="`flex flex-row`">
            <n-icon :name="pdfIcon" class="mr-2" small />
            <a
              class="underline text-brand-purple-500 cursor-pointer"
              @click="() => this.handleFile(payment.document_no)"
            >
              <n-html :text="$t('payments.table.invoice')" />
            </a>
          </div>
        </div>
      </div>
    </accordion-body>
  </Accordion>
</template>

<script>
import format from "../../mixins/format";
import AccordionHeader from "@/components/theme/accordion/accordion-header";
import Accordion from "@/components/theme/accordion/accordion";
import AccordionBody from "@/components/theme/accordion/accordion-body";
import PaymentDetailItem from "@/components/payments/payment-detail-item";
import moment from "moment";

export default {
  name: "components.payments.card-payment-mobile-overview",
  components: {
    PaymentDetailItem,
    AccordionBody,
    Accordion,
    AccordionHeader
  },
  mixin: [format],
  data: () => ({
    internal_collapsed: true,
    collapsed: false,
    pdfIcon: "Pdf"
  }),
  props: {
    payment: Object,
    active: Boolean
  },
  methods: {
    format_date: value => {
      let formattedDate = moment(value).format("DD-MM-YYYY");
      return formattedDate !== "01-01-0001" ? formattedDate : "-";
    },
    format_value: value => (value.replace(" ", "").length ? value : "-"),
    handleFile(paymentId) {
      console.log("handleFile ", paymentId);
      this.$emit("handleFile", paymentId);
    }
  },
  mounted() {
    this.internal_collapsed = this.collapsed;
  }
};
</script>
