<template>
  <div class="col-span-2 lg:col-span-1">
    <div class="font-semibold">
      {{ title }}
    </div>
    <div>
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String
  }
};
</script>
